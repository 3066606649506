<template>
    <section>
        <div class="row px-3 mx-0 align-items-center border-bottom" style="height:50px;">
            <div class="bg-general3 text-white rounded-circle d-middle-center cr-pointer" style="width:22px;height:22px;" @click="verHistorial">
                <i class="icon-left f-18" />
            </div>
            <div class="col-auto f-22 text-general f-600">
                Turno 57875
            </div>
            <i class="icon-fecha-fin text-general ml-auto f-20" />
            <div class="col-auto">
                <p class="f-15 text-general">10 Ago. 2021</p>
                <p class="f-13 text-general">Fecha</p>
            </div>
            <i class="icon-clock text-general f-20" />
            <div class="col-auto">
                <p class="f-15 text-general">10:00 a.m. </p>
                <p class="f-15 text-general">06:00 p.m</p>
            </div>
            <i class="icon-pedidos text-general f-20" />
            <div class="col-auto">
                <p class="f-15 text-general">47 </p>
                <p class="f-13 text-general">Pedidos</p>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col px-0 border-right">
                <div class="row mx-0 align-items-center border-bottom" style="height:50px;">
                    <div class="col-auto px-1 text-general f-600 f-20">
                        Pedidos
                    </div>
                    <div class="col px-2">
                        <el-select v-model="value" class="w-100">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="custom-scroll overflow-auto pt-2" style="height:calc(100vh - 180px)">
                    <div class="row mx-0 mx-2">
                        <div class="col-auto px-1 bg-general br-20" />
                        <div class="col px-0 mx-2 border py-1 px-2 br-8">
                            <div class="row mx-0">
                                <i class="icon-pedidos text-general f-18" />
                                <div class="col pl-2 pr-0 text-general f-15">
                                    <p class="f-500">
                                        No. 584521
                                    </p>
                                    <p>
                                        03 Feb. 10:00 p.m
                                    </p>
                                </div>
                                <div class="col-auto px-0">
                                    <el-tooltip placement="bottom" content="Pedido parcial" effect="light">
                                        <i class="icon-timelapse text-gris f-17" />
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="row mx-0 align-items-center f-15 text-general2">
                                <i class="icon-leechero-cap f-18" />
                                <p class="col pl-2 f-500">
                                    Luis Correa
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0 border-left">
                <div class="row mx-0 pl-3 align-items-center border-bottom" style="height:50px;">
                    <i class="icon-pedidos text-general f-20" />
                    <div class="col-auto text-general f-17 f-600">
                        Pedido 584512
                    </div>
                </div>
                <div class="custom-scroll overflow-auto pt-2" style="height:calc(100vh - 180px)">
                    <div class="row mx-0 border-bottom">
                        <router-link 
                        v-for="(ruta, r) in rutas" 
                        :key="r" 
                        :to="{name: ruta.ruta}" 
                        :class="`col text-center ${ $route.name == ruta.ruta ? 'router-active-edit' : 'text-general2' }`"
                        >
                            {{ ruta.titulo }}
                        </router-link>
                    </div>
                    <!-- <tabs :tabs="rutas" class="justify-content-around" /> -->
                    <router-view />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            rutas: [
                { ruta: 'admin.rutas.ver-driver.mapa', titulo: 'Mapa' },
                { ruta: 'admin.rutas.ver-driver.productos', titulo: 'Productos' },
                { ruta: 'admin.rutas.ver-driver.evidencias', titulo: 'Evidencias' },
            ]
        }
    },
    methods: {
        verHistorial(){
            this.$emit('verTurno')
        }
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
    &:hover{
        color: var(--text-general) !important;
    }
}
.router-active-edit{
    border-bottom: 3px solid var(--text-general) !important;
    color: var(--text-general) !important;
}
</style>